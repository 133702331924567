import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../redux/hook";
import { formatNigeriaPhoneNumber, formatPhoneNumber } from "../utils/lib";
import "react-phone-input-2/lib/bootstrap.css";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { CheckPasswordAvailabilityDocument } from "./__generated__/checkPasswordAvailability";
import { notification } from "antd";
import { UserLoginDocument } from "./__generated__/userLogin";
import { setIsAuthenticated, setUser } from "../redux/slice/auth.slice";
import { RESELLER_TOKEN } from "../utils/constants";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import { RequestOtpDocument } from "./__generated__/requestOTP";
// import { useAuthState } from "react-firebase-hooks/auth";
import { signInWithPopup } from "firebase/auth";
import { auth, googleProvider } from "../utils/firebase";
import { socialLogin } from "../graphql/mutations/auth.mutation";
import {
  SocialMediaLoginMutation,
  SocialMediaLoginMutationVariables,
} from "../graphql/mutations/__generated__/auth.mutation";
import { SocialPlatform } from "../graphql/types";
import { toast } from "sonner";

interface Props {}

const LoginForm = (props: Props) => {
  const dispatch = useDispatch();
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [showPasswordInput, setShowPassword] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [hidePassword, setHidePassword] = useState(false);
  const [showIframe, setShowIframe] = useState(false);
  const { previousPage } = useAppSelector((state) => state.app);
  const navigate = useNavigate();

  // console.log("google", user?.toJSON());

  const [socialLoginMutate, { loading }] = useMutation<
    SocialMediaLoginMutation,
    SocialMediaLoginMutationVariables
  >(socialLogin);

  const handleSocialLogin = async (token: string) => {
    await socialLoginMutate({
      variables: {
        platform: SocialPlatform.Google,
        token: token,
      },
    })
      .then((res) => {
        if (res.data?.socialMediaLogin) {
          toast.success("Login successful");
          localStorage.setItem(
            RESELLER_TOKEN,
            res.data.socialMediaLogin.token || ""
          );
          dispatch(setUser({ ...res.data.socialMediaLogin.user }));
          dispatch(setIsAuthenticated(true));
          if (previousPage) {
            navigate(previousPage);
          } else {
            navigate("/");
          }
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  };

  const signInWithGoogle = async () => {
    try {
      const data = await signInWithPopup(auth, googleProvider);
      // console.log(data);
      data.user.getIdTokenResult().then((res) => {
        handleSocialLogin(res.token);
      });
    } catch (error) {
      console.error("Error signing in with Google:", error);
    }
  };

  //!check password availability
  const [checkPasswordAvailability, { loading: passwordAvailabilityLoading }] =
    useMutation(CheckPasswordAvailabilityDocument, {
      variables: {
        input: {
          phone,
        },
      },
      onError(error) {
        notification.error({
          message: "Failed attempt to login",
          description: error.message,
        });
      },
    });
  const [userLogin] = useMutation(UserLoginDocument, {
    variables: {
      input: {
        password,
        phone,
      },
    },
    onError(error) {
      notification.error({
        message: "Failed attempt to login",
        description: error.message,
      });
    },
    onCompleted() {
      notification.success({
        message: "Success",
        description: "Login successful",
      });
    },
  });
  const [requestOTP] = useMutation(RequestOtpDocument, {
    variables: {
      input: {
        phone,
      },
    },
    onError(error) {
      notification.error({
        message: "Failed to send OTP code",
        description: error.message,
      });
    },
    onCompleted() {
      notification.success({
        message: "Success",
        description:
          "A code has been sent to your phone as SMS. Input the code below",
      });
    },
  });

  // const init = useRef({
  //   navigate,
  // });

  const isLoading = React.useMemo(
    () => passwordAvailabilityLoading,
    [passwordAvailabilityLoading]
  );

  //!old login
  // const onLoginSubmit = (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();
  //   isAuthenticated && message.loading("Loading...", 0);
  //   dispatch(loginUser({ phone }));
  // };

  //!new login
  async function handleLogin(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const { data } = await checkPasswordAvailability();
    if (!data) return;

    const passwordIsAvailable = data?.checkPasswordAvailability;
    //!check if password is available
    if (passwordIsAvailable) {
      setShowPassword(true);
    }

    if (passwordIsAvailable && !["", undefined, null].includes(password)) {
      const userLoginResponse = await userLogin();
      if (userLoginResponse && userLoginResponse.data) {
        const { userLogin } = userLoginResponse.data;

        localStorage.setItem(RESELLER_TOKEN, userLogin?.token || "");
        // localStorage.setItem(RESELLER_COUNTRY, userLogin?.user?.country || "");
        dispatch(setUser({ ...userLogin?.user }));
        dispatch(setIsAuthenticated(true));
        if (previousPage) {
          navigate(previousPage);
        } else {
          navigate("/");
        }
      }
    }

    //!handle when password is not available
    if (!passwordIsAvailable) {
      notification.info({
        message: "Password reset",
        description:
          "Due to a security upgrade of your account, provide a new password",
        duration: 10,
      });
      const { data } = await requestOTP();
      navigate("/auth/reset-password", {
        state: { phone, token: data ? data.requestOTP.token : null },
      });
    }
  }

  const showPassword = () => {
    setHidePassword(!hidePassword);
  };

  //!check if user has accepted terms and conditions and render based on check
  // const showTCInframe = () {
  //   if(!user?.accepted_terms)
  // }
  const user = useAppSelector((state: any) => state.auth.user);

  const checkterms = user? user?.id:
  console.log("check user id" , user?.id)




  return (
    <form onSubmit={handleLogin} className="w-full">
      <div className="text-left mb-9">
        <h1 className="text-3xl text-center">Welcome back ✌🏽</h1>
      </div>
      <div className="mb-5">
        <label htmlFor="phone" className="block text-sm font-medium">
          Your phone number
        </label>
        <div className="mt-2 relative rounded-md shadow-sm">
          <PhoneInput
            country={"gh"}
            enableSearch={true}
            onlyCountries={["gh", "ng"]}
            countryCodeEditable={false}
            inputStyle={{ width: "100%" }}
            inputClass="focus:ring-sokoBlue focus:border-sokoBlue sm:text-sm border-gray-300 py-4"
            placeholder="+233 20xxxxxx"
            value={phone}
            onChange={(value) => {
              if (value.slice(0, 3) === "233") {
                formatPhoneNumber({
                  value,
                  cb: setPhone,
                  errorCb: setPhoneError,
                });
              } else if (value.slice(0, 3) === "234") {
                formatNigeriaPhoneNumber({
                  phone: value,
                  cb: setPhone,
                  errorCb: setPhoneError,
                });
              }
            }}
          />
        </div>
        {phoneError && (
          <span className="text-red-500 mt-1 text-xs font-semibold">
            Invalid phone number
          </span>
        )}
      </div>
      {showPasswordInput && (
        <div className="mt-6">
          <label htmlFor="password" className="block text-sm font-medium">
            Your password
          </label>
          <div className="mt-2 relative rounded-md shadow-sm">
            <input
              type={hidePassword ? "text" : "password"}
              id="password"
              className="focus:ring-sokoBlue focus:border-sokoBlue block w-full pl-7 pr-12 py-4 sm:text-sm border-gray-300 border-2 rounded-md"
              onChange={(e) => setPassword(e.target.value)}
            />
            <div
              className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
              onClick={showPassword}
            >
              {hidePassword ? (
                <EyeOffIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              ) : (
                <EyeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              )}
            </div>
          </div>
          <span
            className="text-blue-500 mt-1 text-xs font-semibold cursor-pointer"
            onClick={async () => {
              const { data } = await requestOTP();

              navigate(
                {
                  pathname: "/auth/reset-password",
                  search: `?forgotten_password=true`,
                },
                { state: { token: data ? data.requestOTP.token : null, phone } }
              );
            }}
          >
            Forgotten Password? Reset my password
          </span>
        </div>
      )}

      <div className="flex flex-col">
        <div className="my-5 w-full">
          <button
            type="submit"
            className={`w-full flex justify-center py-4 px-4 border border-transparent text-base font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 text-white ${
              isLoading ? "cursor-not-allowed bg-gray-300" : "bg-blue-500"
            }`}
            disabled={isLoading}
          >
            {isLoading && (
              <AiOutlineLoading3Quarters className="h-6 w-6 animate-spin text-blue-400 mr-2" />
            )}{" "}
            Login
          </button>
        </div>
        <div>
          <button
            onClick={signInWithGoogle}
            className="w-full bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow flex items-center justify-center transition duration-300 ease-in-out"
            type="button"
          >
            {loading && (
              <AiOutlineLoading3Quarters className="h-6 w-6 animate-spin text-blue-400 mr-2" />
            )}{" "}
            <svg
              className="w-5 h-5 mr-2"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                fill="#4285F4"
              />
              <path
                d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                fill="#34A853"
              />
              <path
                d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                fill="#FBBC05"
              />
              <path
                d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                fill="#EA4335"
              />
              <path d="M1 1h22v22H1z" fill="none" />
            </svg>
            Sign in with Google
          </button>
        </div>
        <p className="text-sm text-center font-semibold text-gray-400 mt-3">
          Not a Reseller yet?{" "}
          <span
            className="ml-1 text-blue-500 cursor-pointer"
            onClick={() => {
              navigate("/auth/register");
            }}
          >
            Create account here
          </span>
        </p>
      </div>
    </form>
  );
};

export default LoginForm;
