import { InformationCircleIcon } from "@heroicons/react/solid";
import { message } from "antd";
import { useMemo, useState } from "react";
import { HiOutlineMinusCircle, HiOutlinePlusCircle } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import { Discount, DiscountType, ProductType } from "../graphql/types";
// import { addProductToCart } from "../redux/actions/cartActions";
import { useAppDispatch, useAppSelector } from "../redux/hook";
import { ICartProduct } from "../utils/types";
import { setToggleCartModal } from "../redux/slice/app.slice";
import { addProductToCart, setDigitalProduct } from "../redux/slice/cart.slice";
import _ from "lodash";
import { useShop } from "../hooks/useShop";
import { getDicountedPrice } from "../utils/lib";

type Props = {};

const AddProductToCartForm = (props: Props) => {
  const dispatch = useAppDispatch();
  const { orderProduct } = useAppSelector((state) => state.orders);
  const { cart } = useAppSelector((state) => state.cart);
  const { user } = useAppSelector((state) => state.auth);
  const [moreProfit, setMoreProfit] = useState(false);
  const [typedSpec, setTypedSpec] = useState(
    orderProduct ? orderProduct.combination_string?.replace("&", "-") : ""
  );
  const [profit, setProfit] = useState(0);
  const [qty, setQty] = useState(1);
  const { shop, loading: shopLoad } = useShop({
    filter: {
      ownerId: {
        eq: user?.id,
      },
    },
  });

  const navigate = useNavigate();

  const reducePriceBy = useMemo(() => {
    if (orderProduct?.discount) {
      if (orderProduct?.discount?.type === DiscountType.FixedAmount) {
        return Number(orderProduct?.discount.amount);
      }
      if (orderProduct?.discount?.type === DiscountType.Percentage) {
        return (
          (Number(orderProduct?.price) *
            Number(orderProduct.discount.percentage)) /
          100
        );
      }
    }
    return 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderProduct?.discount, orderProduct?.price]);

  const ChangeQty = (e: any) => {
    setQty(+e.target.value);
  };

  const inc = () => {
    if (qty < Number(orderProduct?.qty)) {
      setQty(qty + 1);
    }
  };
  const dec = () => {
    if (qty > 1) {
      setQty(qty - 1);
    }
  };
  const existsInCart = cart?.products.find(
    (prod) => prod.productSKU === orderProduct?.sku
  );

  const addToCart = (e: React.FormEvent) => {
    e.preventDefault();

    if (
      _.isEmpty(cart?.products) ||
      Number(existsInCart?.productQty) + Number(qty) <=
        Number(orderProduct?.qty) ||
      !existsInCart?.productSKU.includes(orderProduct?.sku as string)
    ) {
      const productObj: ICartProduct = {
        imageLink: String(orderProduct?.avatar),
        name: String(orderProduct?.title),
        productPrice: String(orderProduct?.price),
        productQty: qty,
        productSKU: String(orderProduct?.sku),
        profitAdded: profit.toString(),
        spec: String(typedSpec),
        collection: orderProduct?.collection,
        newPrice: (orderProduct?.price as number) - reducePriceBy,
        discountAmount: getDicountedPrice(orderProduct as any) ?? 0,
        supplier_id: orderProduct?.supplier_id,
        availableQty: Number(orderProduct?.qty),
        currency: orderProduct?.currency as string,
        discount: orderProduct?.discount as Discount,
        inHouse: orderProduct?.inhouse as Boolean,
      };
      dispatch(addProductToCart(productObj));
      message.success("Product added to cart", 5);
      dispatch(setToggleCartModal(false));
    } else {
      message.error("Product quantity limit reached", 5);
    }
  };

  const orderNow = () => {
    if (
      _.isEmpty(cart?.products) ||
      Number(existsInCart?.productQty) + Number(qty) <=
        Number(orderProduct?.qty) ||
      !existsInCart?.productSKU.includes(orderProduct?.sku as string)
    ) {
      const productObj: ICartProduct & { productType: any } = {
        imageLink: String(orderProduct?.avatar),
        name: String(orderProduct?.title),
        productPrice: String(orderProduct?.price),
        productQty: qty,
        productSKU: String(orderProduct?.sku),
        profitAdded: profit.toString(),
        spec: String(typedSpec),
        supplierPrice: orderProduct?.cost_price,
        collection: orderProduct?.collection,
        newPrice: (orderProduct?.price as number) - reducePriceBy,
        discountAmount: getDicountedPrice(orderProduct as any) ?? 0,
        supplier_id: orderProduct?.supplier_id,
        currency: orderProduct?.currency as string,
        productType: orderProduct?.productType,
        discount: orderProduct?.discount as Discount,
        inHouse: orderProduct?.inhouse as Boolean,
      };
      if (productObj.productType === ProductType.Physical) {
        dispatch(addProductToCart(productObj));
        message.success("Product added to cart", 5);
        dispatch(setToggleCartModal(false));
        navigate("/cart");
      } else {
        navigate("/cart/digital-checkout");
        dispatch(setToggleCartModal(false));
        dispatch(setDigitalProduct(productObj));
      }
    } else {
      message.error("Product quantity limit reached", 5);
    }
  };

  // console.log("dp", getDicountedPrice(orderProduct as any));

  // function clearCartAndAdd(e: React.FormEvent) {
  //   dispatch(clearCart());
  //   addToCart(e);
  //   dispatch(setToggleCartModal(false));
  // }

  // const supplierIds = cart?.products.map((item) => item.supplier_id);

  // if (
  //   cart?.products.length >= 1 &&
  //   !supplierIds.includes(orderProduct?.supplier_id || null)
  // )
  //   return (
  //     <div className="flex">
  //       <div className="w-full flex flex-col flex-1 overflow-y-scroll">
  //         <div className="flex flex-col w-full">
  //           <div className="flex-1 p-2 bg-gray-100 my-2 rounded-md">
  //             <div className="flex items-start">
  //               <div className="flex-shrink-0 pt-0.5">
  //                 <img
  //                   className="h-16 w-16 rounded-md"
  //                   src={orderProduct?.avatar}
  //                   alt=""
  //                 />
  //               </div>
  //               <div className="ml-3 w-0 flex-1 flex flex-col">
  //                 <span className="text-xs font-medium text-gray-500">
  //                   {orderProduct?.title}
  //                 </span>
  //                 <span className="mt-1 text-sm font-bold text-gray-900">
  //                   {orderProduct?.currency} {orderProduct?.price}
  //                 </span>
  //                 <span className="mt-1 text-sm font-medium text-gray-500">
  //                   {orderProduct?.sku}
  //                 </span>
  //               </div>
  //             </div>
  //           </div>
  //           <span className={`text-xs font-medium text-yellow-500 mt-1`}>
  //             You are limited to adding items from a specific supplier one at a
  //             time to your cart.
  //           </span>
  //         </div>
  //         <div className="flex space-x-3 my-2">
  //           <div className="flex w-full">
  //             <button
  //               type="button"
  //               className={`w-full flex justify-center py-4 px-4 text-base font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 border-2 border-blue-500 text-blue-500 hover:bg-blue-600 hover:text-white`}
  //               onClick={clearCartAndAdd}
  //             >
  //               Clear cart and add product
  //             </button>
  //           </div>
  //           <div className="flex w-full">
  //             <button
  //               type="button"
  //               className={`w-full flex justify-center py-4 px-4 border border-transparent text-base font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 ${"bg-blue-500 text-white"}`}
  //               onClick={orderNow}
  //             >
  //               Cancel
  //             </button>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );

  if (shopLoad) return null;

  if (!shop && !shopLoad)
    return (
      <div>
        <div>
          <h2 className="text-lg">Activate your shop</h2>
          <span className="text-gray-700">
            To add profit to a product, you need to activate your shop.
          </span>
        </div>
        <Link
          to={"/shop"}
          onClick={() => dispatch(setToggleCartModal(false))}
          className={`w-full flex justify-center items-center py-3 px-4 border border-transparent text-base font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 bg-blue-500 text-white hover:bg-blue-600 hover:text-white mt-5`}
        >
          Activate shop
        </Link>
      </div>
    );

  return (
    <div className="flex">
      <form
        onSubmit={addToCart}
        className="w-full flex flex-col flex-1 overflow-y-scroll"
      >
        <div className="flex flex-col w-full">
          <div className="flex-1 p-2 bg-gray-100 my-2 rounded-md">
            <div className="flex items-start">
              <div className="flex-shrink-0 pt-0.5">
                <img
                  className="h-16 w-16 rounded-md"
                  src={orderProduct?.avatar}
                  alt=""
                />
              </div>
              <div className="ml-3 w-0 flex-1 flex flex-col">
                <span className="text-xs font-medium text-gray-500">
                  {orderProduct?.title}
                </span>
                <span className="mt-1 text-sm font-bold text-gray-900">
                  {orderProduct?.currency} {orderProduct?.price}
                </span>
                <span className="mt-1 text-sm font-medium text-gray-500">
                  {orderProduct?.sku}
                </span>
              </div>
            </div>
          </div>

          <div className="rounded-md bg-blue-50 p-2">
            <div className="flex">
              <div className="flex-shrink-0">
                <InformationCircleIcon
                  className="h-5 w-5 text-blue-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between">
                <span className="text-sm text-blue-700">
                  You have added {orderProduct?.currency}{" "}
                  {isNaN(profit)
                    ? 0.0
                    : parseFloat(profit.toString()).toFixed(2)}{" "}
                  profit to {qty} quantity
                </span>
              </div>
            </div>
          </div>

          <div className="mb-2 mt-4">
            <label htmlFor="profit" className="block text-sm font-medium">
              Enter your profit
            </label>
            <div className="relative rounded-md shadow-sm">
              <input
                type="number"
                name="profit"
                id="profit"
                required
                className="focus:ring-gray-300 focus:border-gray-300 block w-full pl-7 pr-12 py-2 sm:text-sm border-gray-300 border-2 rounded-md"
                value={profit}
                onChange={(e) => {
                  setProfit(e.target.valueAsNumber);
                  if (
                    e.target.valueAsNumber >
                    (orderProduct ? orderProduct?.price * qty : 0)
                  ) {
                    setMoreProfit(true);
                  } else {
                    setMoreProfit(false);
                  }
                }}
                step="any"
                placeholder="0.0"
              />
            </div>
            {moreProfit && (
              <span className={`text-xs font-medium text-yellow-500 mt-1`}>
                We advice you add profit up to 100% of product price.
              </span>
            )}
          </div>

          {orderProduct?.productType === ProductType.Physical && (
            <div className="my-2">
              <label htmlFor="profit" className="block text-sm font-medium">
                Enter quantity
              </label>
              <div className="relative rounded-md py-1 flex-1">
                <input
                  type="number"
                  id="productQty"
                  name="productQty"
                  value={qty}
                  max={Number(orderProduct?.qty)}
                  className="focus:ring-tendo-active focus:border-tendo-active block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md relative"
                  onChange={(e) => ChangeQty(e)}
                />
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                  <HiOutlinePlusCircle
                    className="h-4 w-4 mx-1 cursor-pointer"
                    onClick={(e) => inc()}
                  />

                  <HiOutlineMinusCircle
                    onClick={(e) => dec()}
                    className="h-4 w-4 mx-1 cursor-pointer"
                  />
                </div>
              </div>
            </div>
          )}

          {orderProduct?.combination_string && (
            <div className="mb-3">
              <label htmlFor="spec" className="block text-sm font-medium">
                Product spec
              </label>
              <div className="mt-2 relative rounded-md shadow-sm">
                <input
                  type="text"
                  id="spec"
                  autoComplete="spec"
                  className="focus:ring-sokoBlue focus:border-sokoBlue block w-full pl-7 pr-12 py-2 sm:text-sm border-gray-300 border-2 rounded-md placeholder:text-gray-200 disabled:bg-gray-200"
                  placeholder="Black small"
                  value={typedSpec}
                  onChange={(e) => {
                    setTypedSpec(e.target.value);
                  }}
                  disabled
                />
              </div>
            </div>
          )}

          <div className="flex space-x-3 my-2">
            {orderProduct?.productType === ProductType.Physical && (
              <div className="flex w-full">
                <button
                  type="submit"
                  className={`w-full flex justify-center py-4 px-4 text-base font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 border-2 border-blue-500 text-blue-500 hover:bg-blue-600 hover:text-white`}
                  // onClick={addToCart}
                >
                  Add to cart
                </button>
              </div>
            )}
            <div className="flex w-full">
              <button
                type="button"
                className={`w-full flex justify-center py-4 px-4 border border-transparent text-base font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 ${"bg-blue-500 text-white"}`}
                onClick={orderNow}
              >
                Order now
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddProductToCartForm;
