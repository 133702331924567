import { Menu, Transition } from "@headlessui/react";
import {
  AcademicCapIcon,
  ChatAlt2Icon,
  ChevronDownIcon,
  CogIcon,
  CreditCardIcon,
  LoginIcon,
  LogoutIcon,
  ShareIcon,
  ShoppingBagIcon,
  TruckIcon,
  UsersIcon,
  CashIcon,
} from "@heroicons/react/solid";
import { uniqueId } from "lodash";
import { Fragment, useState } from "react";
import { NavLink } from "react-router-dom";
import { useFreshDeskScript } from "../hooks/useFreshdeskScript";
import { useAppDispatch, useAppSelector } from "../redux/hook";
import { initFreshChat } from "../services/freshchat.service";
import { classNames } from "../utils/lib";
import { setToggleLogin, setToggleSignOut } from "../redux/slice/app.slice";
import { TicketIcon } from "@heroicons/react/outline";
import { CheckCircle, XCircle } from "lucide-react";

type Props = {};

const LgAccountBtn = (props: Props) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const [isLoadedFreshChat, setLoadedFreshChat] = useState(false);

  function openFreshChat() {
    const fcWidget = (window as any).fcWidget;
    if (fcWidget && fcWidget.isLoaded()) {
      fcWidget.user.setFirstName(user ? user.name.split(" ")[0] : "");
      fcWidget.user.setLastName(user ? user.name.split(" ")[1] : "");
      fcWidget.setExternalId(user ? user.username : uniqueId());
      fcWidget.user.setEmail(user ? user.email : "");
      fcWidget.user.setPhone(user ? user.phone : "");
      console.log("userid", user?.id);
      fcWidget.show();
      fcWidget.open();
    }
  }
  useFreshDeskScript(() => {
    initFreshChat();
    const fcWidget = (window as any).fcWidget;

    fcWidget.on("widget:loaded", () => fcWidget.hide());
    setLoadedFreshChat(true);
    fcWidget.on("widget:closed", () => fcWidget.hide());
  });

  const openSignOutModal = () => {
    dispatch(setToggleSignOut(true));
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex justify-center w-full shadow-sm px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">
          <span className="inline-block h-6 w-6 rounded-full overflow-hidden bg-gray-100">
            <svg
              className="h-full w-full text-gray-300"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
          </span>
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
          {user && (
            <div className="px-4 py-2 flex flex-col space-y-2">
              <span className="text-sm">Signed in as</span>
              <span className="text-sm font-medium text-gray-800 truncate">
                {user?.name}
              </span>
              <span className="text-sm font-medium text-gray-800 truncate">
                {user?.phone}
              </span>
              {user?.verified ? (
                <span className="inline-flex items-center gap-1 px-2.5 py-1 rounded-full font-medium bg-green-100 text-green-700 border border-green-200 w-fit">
                  <CheckCircle className="w-4 h-4" />
                  Verified
                </span>
              ) : (
                <>
                  <span className="inline-flex items-center gap-1 px-2.5 py-1 rounded-full font-medium bg-red-100 text-red-700 border border-red-200 w-fit">
                    <XCircle className="w-4 h-4" />
                    Unverified
                  </span>
                  <NavLink
                    to="/accounts/verify"
                    className="text-blue-600 hover:text-blue-800 font-medium"
                  >
                    Verify account
                  </NavLink>
                </>
              )}
              <span className="text-xs flex space-x-2 font-medium text-gray-500 truncate">
                Referral code: {user?.username}
              </span>
            </div>
          )}
          {!user && (
            <NavLink
              to="/auth"
              className="px-4 py-2 cursor-pointer flex space-x-2"
              onClick={() => dispatch(setToggleLogin(true))}
            >
              <LoginIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />{" "}
              Login
            </NavLink>
          )}
          {user && (
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <NavLink
                    to="/accounts/myorders"
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-800",
                      "group flex items-center px-4 py-2 text-sm"
                    )}
                  >
                    <ShoppingBagIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    My Orders
                  </NavLink>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <NavLink
                    to="/accounts/wallet"
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm"
                    )}
                  >
                    <CreditCardIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Wallet
                  </NavLink>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <NavLink
                    to="/accounts/topup"
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm"
                    )}
                  >
                    <CashIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Top up wallet
                  </NavLink>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <NavLink
                    to="/accounts/claim-coupon"
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm"
                    )}
                  >
                    <TicketIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Claim Coupon
                  </NavLink>
                )}
              </Menu.Item>
            </div>
          )}
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <span
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "group flex items-center px-4 py-2 text-sm cursor-pointer"
                  )}
                  onClick={() => {
                    if (isLoadedFreshChat) {
                      openFreshChat();
                    }
                  }}
                >
                  <ChatAlt2Icon
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Help
                </span>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <NavLink
                  to="/accounts/learn"
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "group flex items-center px-4 py-2 text-sm"
                  )}
                >
                  <AcademicCapIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />{" "}
                  Learn
                </NavLink>
              )}
            </Menu.Item>
          </div>
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <a
                  href={`https://t.me/+bCkYNwztWf03NDVk`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "group flex items-center px-4 py-2 text-sm"
                  )}
                >
                  <UsersIcon
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Join Tendo Community
                </a>
              )}
            </Menu.Item>
            {user && (
              <Menu.Item>
                {({ active }) => (
                  <NavLink
                    to="/accounts/referral"
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm"
                    )}
                  >
                    <ShareIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Refer Friends
                  </NavLink>
                )}
              </Menu.Item>
            )}
          </div>
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <NavLink
                  to="/accounts/delivery-prices"
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "group flex items-center px-4 py-2 text-sm"
                  )}
                >
                  <TruckIcon
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Delivery Prices
                </NavLink>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <NavLink
                  to="/accounts/settings"
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "group flex items-center px-4 py-2 text-sm"
                  )}
                >
                  <CogIcon
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Settings
                </NavLink>
              )}
            </Menu.Item>
          </div>
          {user && (
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <NavLink
                    to="#"
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm"
                    )}
                    onClick={openSignOutModal}
                  >
                    <LogoutIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Sign Out
                  </NavLink>
                )}
              </Menu.Item>
            </div>
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default LgAccountBtn;
