import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ICart,
  ICartProduct,
  ICustomer,

  IReseller,
} from "../../utils/types";
import dayjs from "dayjs";
import { Delivery, Pickup } from "../../graphql/types";
// import { ReductionType } from "../../graphql/types";

interface ICartState {
  cart: ICart;
  digitalProduct: any
  active: boolean;
  createdOn: string;
  orderedOn: string;
  previousFee: number;
  resellerCustomers: Record<string, any>[];
}

const initialState: ICartState = {
  cart: {
    products: [],
    status: '',
    totalAmountToCollect: 0,
    totalResellerProfit: 0,
    couponAmount: 0,
    couponCode: '',
    customer: null,
    delivery: null,
    pickup: null,
    deliveryPaymentStatus: '',
    customer_paid: false,
    paymentMethod: "pay_online",
    coupon: undefined
  },
  digitalProduct: undefined,
  active: false,
  createdOn: "",
  orderedOn: "",
  previousFee: 0,
  resellerCustomers: [],
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addProductToCart: (state, action: PayloadAction<ICartProduct>) => {
      const { cart } = state;
      const product = action.payload;


      if (cart) {
        const products = cart.products;
        const productIndex = products.findIndex(
          (p) => p.productSKU === product.productSKU
        );

        if (productIndex > -1) {
          products[productIndex].productQty += product.productQty;
        } else {
          products.push(product);
        }

        cart.products = products;
        cart.totalAmountToCollect =
          products.reduce((acc, p) => {
            return (
              acc + Number(p.newPrice) * p.productQty + Number(p.profitAdded) * p.productQty ?? 0
            );
          }, 0) + (cart.delivery ? cart.delivery.price : 0);
        cart.totalResellerProfit = products.reduce((acc, p) => {
          return acc + Number(p.profitAdded) * p.productQty ?? 0;
        }, 0);
      } else {
        state.cart = {
          products: [action.payload],
          status: "PENDING",
          totalAmountToCollect:
            Number(product?.newPrice) * Number(product.productQty) +
            Number(product.profitAdded) ?? 0,
          totalResellerProfit: Number(product.profitAdded) ?? 0,
        };
        state.active = true;
        state.createdOn = dayjs().format("YYYY-MM-DD");
      }
    },
    removeProductFromCart: (state, action: PayloadAction<ICartProduct>) => {
      const { cart } = state;
      if (cart) {
        const products = cart.products;
        const productIndex = products.findIndex(
          (p) => p.productSKU === action.payload.productSKU
        );
        if (productIndex > -1) {
          products.splice(productIndex, 1);
        }
        cart.products = products;
        cart.totalAmountToCollect = products.reduce((acc, p) => {
          return (
            acc +
            Number(p.newPrice) * Number(p.productQty) +
            Number(p.profitAdded) ?? 0
          );
        }, 0);
        cart.totalResellerProfit = products.reduce((acc, p) => {
          return acc + Number(p.profitAdded) ?? 0;
        }, 0);
      }
    },
    addDeliveryToCart: (state, action: PayloadAction<Delivery>) => {
      const { cart } = state;
      const snapshot = state;

      if (cart) {
        cart.delivery = action.payload;
        cart.totalAmountToCollect =
          cart.totalAmountToCollect + cart.delivery?.price;

        cart.totalAmountToCollect =
          cart.totalAmountToCollect - snapshot.previousFee;
      }
      state.previousFee = action.payload.price;
    },
    removeDelivery: (state) => {
      const snapshot = state;

      state.cart.totalAmountToCollect =
        state.cart.totalAmountToCollect - snapshot.previousFee;
      state.previousFee = 0;
      state.cart.delivery = null
    },
    removePickup: (state) => {
      state.cart.pickup = null
    },
    addPickUpToCart: (state, action: PayloadAction<Pickup>) => {
      const { cart } = state;
      if (cart) {
        cart.pickup = action.payload
      }
    },
    addCustomerToCart: (state, action: PayloadAction<ICustomer>) => {
      const { cart } = state;
      if (cart) {
        cart.customer = action.payload;
      }
    },
    addResellerToCart: (state, action: PayloadAction<IReseller>) => {
      const { cart } = state;
      if (cart) {
        cart.reseller = action.payload;
      }
    },



    clearCart: (state) => {
      state.cart = {
        products: [],
        status: '',
        totalAmountToCollect: 0,
        totalResellerProfit: 0,
        couponAmount: 0,
        couponCode: '',
        customer: null,
        delivery: null,
        pickup: null,
        deliveryPaymentStatus: '',
      };
      state.active = false;
      state.createdOn = "";
      state.orderedOn = "";
      state.previousFee = 0;
    },
    // setCheckingOut: (state, action: PayloadAction<boolean>) => {
    //   state.checkingOut = action.payload;
    // },
    // setCheckoutStatus: (
    //   state,
    //   action: PayloadAction<Record<string, any> | null>
    // ) => {
    //   state.checkoutStatus = action.payload;
    // },
    editCartProduct: (state, action: PayloadAction<ICartProduct>) => {
      const { cart } = state;
      if (cart) {
        const products = cart.products;
        const productIndex = products.findIndex(
          (p) => p.productSKU === action.payload.productSKU
        );
        if (productIndex > -1) {
          products[productIndex] = action.payload;
        }
        cart.products = products;
        cart.totalAmountToCollect =
          products.reduce((acc, p) => {
            return (
              acc +
              Number(p.newPrice) * Number(p.productQty) +
              Number(p.profitAdded) * p.productQty ?? 0
            );
          }, 0) + (cart.delivery ? cart.delivery.price : 0);
        cart.totalResellerProfit = products.reduce((acc, p) => {
          return acc + Number(p.profitAdded) * p.productQty ?? 0;
        }, 0);
      }
    },
    setCustomerPaid: (state, action: PayloadAction<boolean>) => {
      state.cart.customer_paid = action?.payload
    }
    ,
    setPaymentMethod: (state, action: PayloadAction<string>) => {
      state.cart.paymentMethod = action?.payload
    },
    setResellerCustomers: (
      state,
      action: PayloadAction<Record<string, any>[]>
    ) => {
      state.resellerCustomers = action.payload;
    },
    // setCoupon: (state, action: PayloadAction<Record<string, any> | null>) => {
    //   state.coupon = action.payload;
    // },
    // setNavigate: (state, action: PayloadAction<boolean>) => {
    //   state.navigate = action.payload;
    // },
    // setUsePrompt: (state, action: PayloadAction<boolean>) => {
    //   state.promptUser = action.payload;
    // },
    setCartPayment: (state, action: PayloadAction<Record<string, any>>) => {
      const { cart } = state;
      const { payload } = action;
      if (cart) {
        cart.paymentId = payload?.reference;
        cart.paymentMethod = payload?.method;
        cart.paymentStatus = payload?.status;
        cart.deliveryPaymentStatus = payload?.deliveryPaymentStatus ?? "Paid";
      }
    },
    setDigitalProduct: (state, action: PayloadAction<any>) => {
      state.digitalProduct = action.payload;
    },
    setCoupon: (state, action: PayloadAction<any>) => {
      state.cart.coupon = action.payload
    },
    clearCoupon: (state) => {
      state.cart.coupon = undefined
    }
  },
});

export const {
  addProductToCart,
  removeProductFromCart,
  addCustomerToCart,
  addDeliveryToCart,
  addResellerToCart,
  clearCart,
  removeDelivery,
  removePickup,
  setPaymentMethod,
  // setCheckingOut,
  // setCheckoutStatus,
  editCartProduct,
  setResellerCustomers,
  setDigitalProduct,
  // setCoupon,
  // setCouponCode,
  // setNavigate,
  // setUsePrompt,
  setCustomerPaid,
  setCartPayment,
  addPickUpToCart,
  setCoupon,
  clearCoupon
} = cartSlice.actions;

export default cartSlice.reducer;
