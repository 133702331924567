import { gql } from "@apollo/client";

export const requestEmailVerification = gql`mutation RequestEmailVerification($input: requestEmailVerificationInput) {
    requestEmailVerification(input: $input)
  }`

export const requestPhoneVerification = gql`
mutation RequestPhoneVerification($input: checkPasswordAvailabilityInput) {
  requestPhoneVerification(input: $input) {
    code
    token
  }
}`

export const socialLogin = gql`
mutation SocialMediaLogin($token: String!, $platform: SocialPlatform!) {
  socialMediaLogin(token: $token, platform: $platform) {
    token
    user {
      id
      email
      name
      country
      phone
      verified
      user_type
      username
      supplier_shop_id
      created_at
      updated_at
      verifiedDetails {
        has_verified_phonenumber
        has_verified_email
        setup_complete
      }
      details {
        id
        gender
        dob
        referred_by
        phone_verified
        email_verified
        user_id
      }
    }
  }
}
`

export const releaseUser = gql `
  mutation Mutation {
    releaseUserAccount
  }`