import { gql } from "@apollo/client";

export const GET_SHOP = gql`
query GetShop($filter: ShopFilter!) {
  getShop(filter: $filter) {
    id
    name
    description
    contactEmail
    email
    currency
    slug
    primaryDomain
    sokoWorldDomain
    customDomains
    hasBeenSetup
    isActive
    shopTags
    createdAt
    updatedAt
    avatar
    country
    coverImage
    deliveryOption
    deliveryAgent
    paymentOptions
    url
    shortUrl
    phone
    ownerId
    supplierId
    shopId
    locations {
      id
      lat
      long
      createdAt
      updatedAt
    }
  }
}
`