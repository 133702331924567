// import { Select } from "antd";
import { useEffect } from "react";
import { Product } from "../graphql/types";
// import useGetProducts from "../hooks/useGetProducts";
import { useAppDispatch, useAppSelector } from "../redux/hook";
import {
  setCategoryFilter,
  setProductSearchValue,
  // setSearchProduct,
} from "../redux/slice/product.slice";
// import SearchItem from "./SearchItem";
import useGetProductCategories from "../hooks/useGetProductCategories";
import { ListFilter } from "lucide-react";
import { Popover } from "@mantine/core";

interface Props {
  data: Product[];
  onSearch?: (e: string) => void;
}

const Search = ({ data, onSearch }: Props) => {
  const { user } = useAppSelector((state) => state.auth);
  const { categoryFilter } = useAppSelector((state) => state.products);
  // const [category, setCategory] = useState("");

  // const { Option } = Select;
  const dispatch = useAppDispatch();
  // const { loading, refetch } = useGetProducts({});
  const { categories } = useGetProductCategories({
    filter: {
      country: {
        eq: user?.country,
      },
    },
  });

  // console.log(categories);

  // const options = useMemo(() => {
  //   return data.map((item) => (
  //     <Option key={item.sku} value={item.id}>
  //       <SearchItem item={item} />
  //     </Option>
  //   ));
  // }, [Option, data]);

  function onChange(value: any) {
    dispatch(setProductSearchValue(value));
  }

  // async function _onSearch(val: string) {
  //   console.log("search:", val);
  //   if (val.length >= 3) {
  //     if (data.length !== 0) {
  //       const filteredData = data.filter((item) => {
  //         return (
  //           item.title.toLowerCase().includes(val.toLowerCase()) ||
  //           item?.sku?.toLowerCase().includes(val.toLowerCase()) ||
  //           item?.tags?.includes(val.toLowerCase())
  //         );
  //       });
  //       if (filteredData.length !== 0) {
  //         dispatch(setSearchProduct(filteredData));
  //       } else {
  //         const { data: moreFilterData } = await refetch({
  //           filter: {
  //             qty: {
  //               gt: 0,
  //             },
  //             title: {
  //               like: val,
  //             },
  //             sku: {
  //               like: val,
  //             },
  //             tags: {
  //               like: val,
  //             },
  //           },
  //           condition: Condition.Or,
  //         });
  //         dispatch(setSearchProduct(moreFilterData.getProducts as Product[]));
  //       }
  //     } else {
  //       dispatch(setSearchProduct(data));
  //     }
  //   } else {
  //     dispatch(setSearchProduct(data));
  //   }
  // }

  useEffect(() => {
    if (categoryFilter !== undefined) {
      console.log("hi");
      onSearch && onSearch("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryFilter]);

  return (
    <div className="flex items-center gap-3">
      {/* <Select
        showSearch
        placeholder="Search product name, sku or tag"
        optionFilterProp="children"
        onChange={onSearch ? (e) => onSearch(e) : (e) => onChange(e)}
        onSearch={
          onSearch
            ? (e) => [onSearch(e), setSearchVal(e)]
            : (e) => [_onSearch(e), setSearchVal(e)]
        }
        filterOption={false}
        className="w-full search-bar"
        loading={loading}
      >
        {options}
      </Select> */}
      <input
        onChange={
          onSearch ? (e) => onSearch(e.target.value) : (e) => onChange(e)
        }
        type="text"
        className="w-[250px] search-bar py-1 placeholder:text-sm"
        placeholder="Search product name, sku or tag"
      />
      <Popover width={300} position="bottom" withArrow shadow="md">
        <Popover.Target>
          <ListFilter className="h-6 w-6 text-gray-500" />
        </Popover.Target>
        <Popover.Dropdown>
          <div className="grid gap-3">
            <div>
              <div
                onClick={() => [
                  // setCategory(item?.name as string),
                  // filterCategory(item?.name as string),
                  dispatch(setCategoryFilter("")),
                ]}
                className="flex items-center justify-between gap-2 cursor-pointer"
              >
                <span>None</span>
                <input
                  type="checkbox"
                  className="rounded-full"
                  checked={categoryFilter === ""}
                  // checked={category === item?.name}
                  onChange={() => [
                    // setCategory(item?.name as string),
                    // filterCategory(item?.name as string),
                    dispatch(setCategoryFilter("")),
                  ]}
                />
              </div>
            </div>
            {categories.map((item) => (
              <div key={item?.id}>
                <div
                  onClick={() => [
                    // setCategory(item?.name as string),
                    // filterCategory(item?.name as string),
                    dispatch(setCategoryFilter(item?.name as string)),
                  ]}
                  className="flex items-center justify-between gap-2 cursor-pointer"
                >
                  <span>{item?.name}</span>
                  <input
                    type="checkbox"
                    className="rounded-full"
                    checked={categoryFilter === item?.name}
                    // checked={category === item?.name}
                    onChange={() => [
                      // setCategory(item?.name as string),
                      // filterCategory(item?.name as string),
                      dispatch(setCategoryFilter(item?.name as string)),
                    ]}
                  />
                </div>
              </div>
            ))}
          </div>
        </Popover.Dropdown>
      </Popover>
    </div>
  );
};

export default Search;
