import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { notification } from "antd";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useAppSelector } from "../../../redux/hook";
import { RequestOtpDocument } from "../../../components/__generated__/requestOTP";
import { VerifyOtpDocument } from "../../../components/__generated__/verifyOTP";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { UserDocument } from "../../../graphql/queries/__generated__/auth.query";
import { CheckCircle } from "lucide-react";
import { setUser } from "../../../redux/slice/auth.slice";

const VerifyUserOTP = () => {
  const user = useAppSelector((state) => state.auth.user);
  const { data: userData } = useQuery(UserDocument, {
    variables: {
      userId: user?.id,
    },
  });

  const [phoneOtp, setPhoneOtp] = useState("");
  const [emailOtp, setEmailOtp] = useState("");
  const [phoneToken, setPhoneToken] = useState("");
  const [emailToken, setEmailToken] = useState("");
  const [showPhoneOTPInput, setShowPhoneOTPInput] = useState(false);
  const [showEmailOTPInput, setShowEmailOTPInput] = useState(false);
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Phone OTP Request
  const [requestPhoneOTP, { loading: isRequestingPhoneOTP }] = useMutation(
    RequestOtpDocument,
    {
      variables: {
        input: {
          phone: user?.phone,
        },
      },
      onError(error) {
        notification.error({
          message: "Failed to send phone OTP",
          description: error.message,
        });
      },
      onCompleted(data) {
        if (data && data.requestOTP) {
          setPhoneToken(String(data.requestOTP.token));
          setShowPhoneOTPInput(true);
          notification.success({
            message: "Success",
            description:
              "A code has been sent to your phone as SMS. Input the code below",
          });
        }
      },
    }
  );

  // Email OTP Request
  const [requestEmailOTP, { loading: isRequestingEmailOTP }] = useMutation(
    RequestOtpDocument,
    {
      variables: {
        input: {
          email: user?.email,
        },
      },
      onError(error) {
        notification.error({
          message: "Failed to send email OTP",
          description: error.message,
        });
      },
      onCompleted(data) {
        if (data && data.requestOTP) {
          setEmailToken(String(data.requestOTP.token));
          setShowEmailOTPInput(true);
          notification.success({
            message: "Success",
            description:
              "A code has been sent to your email. Input the code below",
          });
        }
      },
    }
  );

  // Phone Verification
  const [verifyPhoneOTP, { loading: isVerifyingPhoneOTP }] = useMutation(
    VerifyOtpDocument,
    {
      variables: {
        input: {
          code: phoneOtp,
          medium: "phone",
          token: phoneToken,
          update: true,
        },
      },
      refetchQueries: [
        {
          query: UserDocument,
          variables: {
            userId: user?.id,
          },
        },
      ],
      onError(error) {
        notification.error({
          message: "Failed to verify phone",
          description: error.message,
        });
      },
      onCompleted() {
        notification.success({
          message: "Success",
          description: "Phone verified successfully!",
        });
        setShowPhoneOTPInput(false);
        setPhoneOtp("");
        setPhoneToken("");
      },
    }
  );

  // Email Verification
  const [verifyEmailOTP, { loading: isVerifyingEmailOTP }] = useMutation(
    VerifyOtpDocument,
    {
      variables: {
        input: {
          code: emailOtp,
          medium: "email",
          token: emailToken,
          update: true,
        },
      },
      refetchQueries: [
        {
          query: UserDocument,
          variables: {
            userId: user?.id,
          },
        },
      ],
      onError(error) {
        notification.error({
          message: "Failed to verify email",
          description: error.message,
        });
      },
      onCompleted() {
        notification.success({
          message: "Success",
          description: "Email verified successfully!",
        });
        setShowEmailOTPInput(false);
        setEmailOtp("");
        setEmailToken("");
      },
    }
  );

  useEffect(() => {
    if (userData?.user?.verifiedDetails) {
      const phoneVerified =
        userData.user.verifiedDetails.has_verified_phonenumber || false;
      const emailVerified =
        userData.user.verifiedDetails.has_verified_email || false;
      setIsPhoneVerified(phoneVerified);
      setIsEmailVerified(emailVerified);
      if (
        phoneVerified &&
        emailVerified &&
        !user?.verified &&
        userData.user.verified
      ) {
        dispatch(setUser(userData.user));
      }
    }
  }, [userData, user, dispatch]);

  return (
    <div className=" mx-48  mt-10 p-6 bg-white ">
      <h1 className="text-3xl text-center">Verify Your Account</h1>

      {/* Phone Verification Section */}
      <div className="mb-8">
        <h2 className="text-lg font-semibold mb-4">Phone Verification</h2>
        {isPhoneVerified ? (
          <div className="flex items-center text-green-600 gap-2">
            <CheckCircle className="w-5 h-5" />
            <span>Phone number verified successfully</span>
          </div>
        ) : !showPhoneOTPInput ? (
          <div>
            <p className="text-gray-600 mb-4">
              Click below to receive a verification code on your phone number:{" "}
              {user?.phone}
            </p>
            <button
              onClick={() => requestPhoneOTP()}
              disabled={isRequestingPhoneOTP || isPhoneVerified}
              className={`w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-white ${
                isRequestingPhoneOTP || isPhoneVerified
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              {isRequestingPhoneOTP && (
                <AiOutlineLoading3Quarters className="h-5 w-5 animate-spin mr-2" />
              )}
              {isPhoneVerified
                ? "Phone Verified"
                : isRequestingPhoneOTP
                ? "Sending Code..."
                : "Send Phone Code"}
            </button>
          </div>
        ) : (
          <div>
            <p className="text-gray-600 mb-4">
              Enter the verification code sent to your phone number
            </p>
            <div className="mb-4">
              <input
                type="text"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter phone OTP code"
                value={phoneOtp}
                onChange={(e) => setPhoneOtp(e.target.value)}
              />
            </div>

            <div className="flex items-center justify-between mb-4">
              <button
                onClick={() => requestPhoneOTP()}
                disabled={isRequestingPhoneOTP}
                className="text-blue-500 hover:text-blue-600 text-sm"
              >
                {isRequestingPhoneOTP ? "Sending..." : "Resend Phone Code"}
              </button>
            </div>

            <button
              onClick={() => verifyPhoneOTP()}
              disabled={
                isVerifyingPhoneOTP || phoneOtp.length === 0 || isPhoneVerified
              }
              className={`w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-white ${
                isVerifyingPhoneOTP || phoneOtp.length === 0 || isPhoneVerified
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              {isVerifyingPhoneOTP && (
                <AiOutlineLoading3Quarters className="h-5 w-5 animate-spin mr-2" />
              )}
              {isPhoneVerified
                ? "Phone Verified"
                : isVerifyingPhoneOTP
                ? "Verifying..."
                : "Verify Phone"}
            </button>
          </div>
        )}
      </div>

      {/* Email Verification Section */}
      <div className="mb-8">
        <h2 className="text-lg font-semibold mb-4">Email Verification</h2>
        {isEmailVerified ? (
          <div className="flex items-center text-green-600 gap-2">
            <CheckCircle className="w-5 h-5" />
            <span>Email verified successfully</span>
          </div>
        ) : !showEmailOTPInput ? (
          <div>
            <p className="text-gray-600 mb-4">
              Click below to receive a verification code on your email:{" "}
              {user?.email}
            </p>
            <button
              onClick={() => requestEmailOTP()}
              disabled={isRequestingEmailOTP || isEmailVerified}
              className={`w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-white ${
                isRequestingEmailOTP || isEmailVerified
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              {isRequestingEmailOTP && (
                <AiOutlineLoading3Quarters className="h-5 w-5 animate-spin mr-2" />
              )}
              {isEmailVerified
                ? "Email Verified"
                : isRequestingEmailOTP
                ? "Sending Code..."
                : "Send Email Code"}
            </button>
          </div>
        ) : (
          <div>
            <p className="text-gray-600 mb-4">
              Enter the verification code sent to your email
            </p>
            <div className="mb-4">
              <input
                type="text"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter email OTP code"
                value={emailOtp}
                onChange={(e) => setEmailOtp(e.target.value)}
              />
            </div>

            <div className="flex items-center justify-between mb-4">
              <button
                onClick={() => requestEmailOTP()}
                disabled={isRequestingEmailOTP}
                className="text-blue-500 hover:text-blue-600 text-sm"
              >
                {isRequestingEmailOTP ? "Sending..." : "Resend Email Code"}
              </button>
            </div>

            <button
              onClick={() => verifyEmailOTP()}
              disabled={
                isVerifyingEmailOTP || emailOtp.length === 0 || isEmailVerified
              }
              className={`w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-white ${
                isVerifyingEmailOTP || emailOtp.length === 0 || isEmailVerified
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              {isVerifyingEmailOTP && (
                <AiOutlineLoading3Quarters className="h-5 w-5 animate-spin mr-2" />
              )}
              {isEmailVerified
                ? "Email Verified"
                : isVerifyingEmailOTP
                ? "Verifying..."
                : "Verify Email"}
            </button>
          </div>
        )}
      </div>

      {/* Verification Status */}
      <div className="text-center mt-4">
        <p className="text-sm font-medium text-gray-600">
          {isPhoneVerified && isEmailVerified
            ? "Both verified"
            : `Please complete ${!isPhoneVerified ? "phone" : ""} ${
                !isEmailVerified
                  ? isPhoneVerified
                    ? "email"
                    : "and email"
                  : ""
              } verification`}
        </p>
      </div>
      <NavLink to="/" className="bg-blue-600 hover:bg-blue-800 p-4 rounded text-white font-medium w-fit">
        Back Home
      </NavLink>
    </div>
  );
};

export default VerifyUserOTP;
