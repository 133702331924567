import ScreenWrapper from "../../../components/common/ScreenWrapper";
import successImg from "../../../assets/images/successimg.svg";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { toast } from "sonner";

interface LocationState {
  storefrontURL?: string;
}

const OrderSuccess = () => {
  const location = useLocation();
  const state = location.state as LocationState;
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(
      () => {
        setIsCopied(true);
        toast.success("Link copied to clipboard!");
        setTimeout(() => setIsCopied(false), 2000);
      },
      (err) => {
        console.error("Could not copy text: ", err);
        toast.error("Failed to copy link");
      }
    );
  };
  return (
    <ScreenWrapper showBackBtn title="Success">
      <div className="mx-4 flex flex-col lg:mb-12 py-16 lg:max-w-7xl lg:mx-auto lg:px-4">
        <div className="pt-20 flex items-center flex-col">
          <img className="h-72 w-full" src={successImg} alt="success" />
          <span className="font-semibold">
            Your order has been placed successfully 🎊{" "}
          </span>
          {state?.storefrontURL && (
            <div className="mt-4 p-4 bg-blue-100 rounded-md w-full">
              <h3 className=" text-sm mb-2">Share this link with your customer</h3>
              <div className="flex items-center mt-2">
                {/* <p className="mr-2"><strong>Storefront URL:</strong></p> */}
                <a
                  href={state.storefrontURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:underline truncate flex-grow"
                >
                  {state.storefrontURL}
                </a>
                <button
                  onClick={() => copyToClipboard(state.storefrontURL || "")}
                  className={`ml-2 px-3 py-1 rounded ${
                    isCopied
                      ? "bg-green-500 text-white"
                      : "bg-blue-500 text-white hover:bg-blue-600"
                  }`}
                >
                  {isCopied ? "Copied!" : "Copy"}
                </button>
              </div>
            </div>
          )}
          <Link
            to={"/"}
            className={` flex mt-4 justify-center py-2 px-4 border text-sm  rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 border-blue-5500 text-blue-500 `}
          >
            Back to home
          </Link>
        </div>
      </div>
    </ScreenWrapper>
  );
};

export default OrderSuccess;
