import { useQuery } from "@apollo/client"
import { GET_SHOP } from "../graphql/queries/shop.query"
import { GetShopQuery, GetShopQueryVariables } from "../graphql/queries/__generated__/shop.query"
import { useAppSelector } from "../redux/hook"

export const useShop = (variables?: GetShopQueryVariables) => {
    const { user } = useAppSelector((state) => state.auth)
    const { data, ...rest } = useQuery<GetShopQuery, GetShopQueryVariables>(GET_SHOP, {
        variables: {
            filter: {
                ownerId: {
                    eq: user?.id
                }
            }
        },
        ...variables
    })
    return {
        shop: data?.getShop,
        ...rest
    }
}