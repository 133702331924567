import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import { useAppSelector } from "../redux/hook";
import { formatNigeriaPhoneNumber, formatPhoneNumber } from "../utils/lib";

import "react-phone-input-2/lib/bootstrap.css";
import { useDispatch } from "react-redux";
import { message } from "antd";
import _ from "lodash";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";
import { AccountRegisterInput } from "../graphql/types";
import { AccountRegisterDocument } from "./__generated__/accountRegister";
import { useMutation } from "@apollo/client";
import { notification } from "antd";
import { RequestOtpDocument } from "./__generated__/requestOTP";
import { VerifyOtpDocument } from "./__generated__/verifyOTP";
import { UpdateUserDetailsDocument } from "./__generated__/updateUserDetails";
import { RESELLER_COUNTRY, RESELLER_TOKEN } from "../utils/constants";
import { setUser } from "../redux/slice/auth.slice";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import { UserLoginDocument } from "./__generated__/userLogin";
import { signInWithPopup } from "firebase/auth";
import { auth, googleProvider } from "../utils/firebase";

const RegisterForm = () => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<AccountRegisterInput>();
  const location = useLocation();

  const refLink = new URLSearchParams(location.search);
  const refCode = refLink?.get("refCode");

  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [token, setToken] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [password, setPassword] = useState("");
  const [dob, setDob] = useState("");
  const [isMale, setIsMale] = useState(false);
  const [showOTPInput, setShowOTPInput] = useState(false);
  const [referredBy, setReferredBy] = useState(refCode ?? "");
  const [hidePassword, setHidePassword] = useState(false);
  const [addDetailsInput, setAddDetailsInput] = useState(false);
  const { user } = useAppSelector((state) => state.auth);
  const [otpPhone, setOtpPhone] = useState("");
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [phoneToken, setPhoneToken] = useState("");

  const navigate = useNavigate();

  // const init = useRef({
  //   navigate,
  // });

  useEffect(() => {
    const el = document.getElementById("referralCode") as HTMLInputElement;
    if (refCode) {
      el.value = refCode ?? "TENDO";
    }
  }, [refCode]);

  const [registerAccount, { loading: isLoadingRegisterAccount }] = useMutation(
    AccountRegisterDocument,
    {
      onError(error) {
        notification.error({
          message: "Failed to register user",
          description: error.message,
        });
      },
      onCompleted() {
        notification.success({
          message: "Success!",
          description: "User registered successfully",
        });
        notification.success({
          message: "We've send an OTP code to verify your account",
        });
      },
    }
  );
  const [userLogin] = useMutation(UserLoginDocument, {
    variables: {
      input: {
        password,
        phone,
      },
    },
    onError(error) {
      console.log("error, login user");
    },
    onCompleted() {
      console.log("login success");
    },
  });
  const [updateUserDetails, { loading: isLoadingUpdateUserDetails }] =
    useMutation(UpdateUserDetailsDocument, {
      onError(error) {
        notification.error({
          message: "Failed to update user details",
          description: error.message,
        });
      },
      onCompleted() {
        notification.success({
          message: "Success!",
          description: "User details updated successfully",
        });
        navigate("/auth/onboard");
      },
    });

  // Mutation to verify phone
  const [verifyPhoneOTP, { loading: isVerifyingPhoneOTP }] = useMutation(
    VerifyOtpDocument,
    {
      onError(error) {
        notification.error({
          message: "Phone verification failed",
          description: error.message,
        });
      },
      onCompleted() {
        notification.success({
          message: "Success",
          description: "Phone number verified successfully",
        });
        setIsPhoneVerified(true);
          setShowOTPInput(false);
          setAddDetailsInput(true);
      },
    }
  );



  // Handler for phone verification
  const handleVerifyPhoneOTP = async () => {
    if (!otpPhone) return;

    try {
      await verifyPhoneOTP({
        variables: {
          input: {
            code: otpPhone,
            medium: "phone",
            token,
            update: true,
          },
        },
      });
    } catch (error) {
      console.error("Phone verification error:", error);
    }
  };



  //Mutation to request otp for phone verification
  const [requestPhoneOTP, { loading: isRequestingPhoneOTP }] = useMutation(
    RequestOtpDocument,
    {
      variables: {
        input: {
          phone,
        },
      },
      onError(error) {
        notification.error({
          message: "Failed to send OTP code",
          description: error.message,
        });
      },
      onCompleted() {
        notification.success({
          message: "Success",
          description:
            "A code has been sent to your phone as SMS. Input the code below",
        });
      },
    }
  );



  const handlePhoneRequestOTP = async () => {
    const { data } = await requestPhoneOTP({
      variables: {
        input: {
          phone,
        },
      },
    });
    if (data?.requestOTP?.token) {
      setPhoneToken(data.requestOTP.token);
    }
  };


  const signInWithGoogle = async () => {
    try {
      const data = await signInWithPopup(auth, googleProvider);
      if (data) {
        setValue("email", data.user.email as string);
        setValue("name", data.user.displayName as string);
        setValue("social_login", true);
      }
      console.log(data);
      data.user.getIdTokenResult().then((res) => {
        setPassword(res.token);
      });
    } catch (error) {
      console.error("Error signing in with Google:", error);
    }
  };

  const onSignUpSubmit = async (data: AccountRegisterInput) => {
    data.country = country;
    data.password = password;
    data.phone = phone;

    if (_.isEmpty(data) || _.isEmpty(country))
      return message.error("Form fields can not be empty", 5);

    if (["", undefined, null].includes(data?.phone)) {
      message.error("Phone number can not be empty", 5);
      return;
    }

    if (!showOTPInput && !addDetailsInput) {
      const { data: registerAccountData } = await registerAccount({
        variables: {
          input: {
            ...data,
          },
        },
      });
      if (registerAccountData && registerAccountData.accountRegister) {
        const { token, user } = registerAccountData.accountRegister;
        //! @Evans use token and user here
        localStorage.setItem(RESELLER_TOKEN, token);
        localStorage.setItem(RESELLER_COUNTRY, String(user?.country));
        setToken(String(token));
        dispatch(setUser(user));
        setShowOTPInput(true);
      }
    } else {
      //!@Evans
      await updateUserDetails({
        variables: {
          input: {
            user_id: Number(user?.id),
            dob,
            gender: isMale ? "Male" : "Female",
            referred_by: referredBy,
          },
        },
      });
      const { data } = await userLogin();
      if (data && data.userLogin) {
        localStorage.setItem(RESELLER_TOKEN, data?.userLogin?.token || "");
        dispatch(setUser({ ...data?.userLogin?.user }));
      }
    }
  };

  const showPassword = () => {
    setHidePassword(!hidePassword);
  };

  return (
    <div className="">
      {showOTPInput ? (
        <div className="">
          <div className="text-left mb-4">
            <h1 className="text-3xl text-center">Verify your account 😎</h1>
          </div>

          {/* Phone Verification Section */}
          <div className="mb-6">
            <span className="text-sm">
              We have sent you a code to your phone number as SMS, enter it
              below to continue.
            </span>
          </div>
          <div className="mb-2">
            <label htmlFor="phoneOTP" className="block text-sm font-medium">
              Enter the 4-digit code you received on your phone here.
            </label>
            <div className="my-2 relative rounded-md shadow-sm">
              <input
                required
                type="text"
                value={otpPhone}
                id="phoneOTP"
                className="focus:ring-sokoBlue focus:border-sokoBlue block w-full pl-7 pr-12 py-4 sm:text-sm border-gray-300 border-2 rounded-md"
                onChange={(e) => setOtpPhone(e.target.value)}
              />
            </div>
            <div className="flex items-center">
              <span className="text-xs font-semibold mr-2 mt-1">
                Did not receive code?
              </span>
              <div
                className="text-blue-500 mt-1 text-xs font-semibold cursor-pointer"
                onClick={handlePhoneRequestOTP}
              >
                {isRequestingPhoneOTP ? "Please wait..." : "Resend code"}
              </div>
            </div>
            <div className="my-5 w-full">
              <button
                type="button"
                className={`w-full flex justify-center py-4 px-4 border border-transparent text-base font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 text-white ${
                  isVerifyingPhoneOTP || !otpPhone || isPhoneVerified
                    ? "cursor-not-allowed bg-gray-300"
                    : "bg-blue-500"
                }`}
                disabled={isVerifyingPhoneOTP || !otpPhone || isPhoneVerified}
                onClick={handleVerifyPhoneOTP}
              >
                {isVerifyingPhoneOTP && (
                  <AiOutlineLoading3Quarters className="h-6 w-6 animate-spin text-blue-400 mr-2" />
                )}
                {isPhoneVerified ? "Phone Verified ✓" : "Verify Phone"}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSignUpSubmit)}>
          {!addDetailsInput && (
            <>
              <div className="text-left mb-4">
                <h1 className="text-3xl text-center">Create your account 👌🏾</h1>
              </div>
              {!watch("social_login") && (
                <div className="mb-3">
                  <label htmlFor="email" className="block text-sm font-medium">
                    Your full name
                  </label>
                  <div className="mt-2 relative rounded-md shadow-sm">
                    <input
                      type="text"
                      id="name"
                      autoComplete="name"
                      className="focus:ring-sokoBlue focus:border-sokoBlue block w-full pl-7 pr-12 py-4 sm:text-sm border-gray-300 border-2 rounded-md"
                      placeholder="John Doe"
                      {...register("name", { required: true })}
                    />
                  </div>
                  {errors.name?.type === "required" && (
                    <span
                      className={`block text-xs text-red-600 mt-2 font-semibold`}
                    >
                      Please enter your full legal name
                    </span>
                  )}
                </div>
              )}
              <div className="mb-3">
                <label htmlFor="phone" className="block text-sm font-medium">
                  Your phone number
                </label>
                <div className="mt-2 relative rounded-md shadow-sm">
                  <PhoneInput
                    country={"gh"}
                    onlyCountries={["gh", "ng"]}
                    enableSearch={true}
                    inputStyle={{ width: "100%" }}
                    inputClass="focus:ring-gray-300 focus:border-gray-300 sm:text-sm border-gray-300 border-2 py-4"
                    placeholder="+233 20xxxxxx"
                    value={phone}
                    countryCodeEditable={false}
                    onChange={(value) => {
                      if (value.slice(0, 3) === "233") {
                        setCountry("GH");
                        formatPhoneNumber({
                          value,
                          cb: setPhone,
                          errorCb: setPhoneError,
                        });
                      } else if (value.slice(0, 3) === "234") {
                        setCountry("NG");
                        formatNigeriaPhoneNumber({
                          phone: value,
                          cb: setPhone,
                          errorCb: setPhoneError,
                        });
                      }
                    }}
                  />
                </div>
                {phoneError && (
                  <span className="text-red-500 mt-1 text-xs font-semibold">
                    Invalid phone number
                  </span>
                )}
              </div>

              {!watch("social_login") && (
                <div className="mb-3">
                  <label htmlFor="email" className="block text-sm font-medium">
                    Your email
                  </label>
                  <div className="mt-2 relative rounded-md shadow-sm">
                    <input
                      type="email"
                      id="email"
                      autoComplete="email"
                      className="focus:ring-gray-300 focus:border-gray-300 block w-full pl-7 pr-12 py-4 sm:text-sm border-gray-300 border-2 rounded-md"
                      placeholder="example@mail.com"
                      {...register("email", { required: true })}
                    />
                  </div>
                  {errors.email?.type === "required" && (
                    <span
                      className={`block text-xs text-red-600 mt-2 font-semibold`}
                    >
                      Please enter your email
                    </span>
                  )}
                </div>
              )}

              {!watch("social_login") && (
                <div className="mt-6">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium"
                  >
                    Your password
                  </label>
                  <div className="mt-2 relative rounded-md shadow-sm">
                    <input
                      type={hidePassword ? "text" : "password"}
                      id="password"
                      className="focus:ring-sokoBlue focus:border-sokoBlue block w-full pl-7 pr-12 py-4 sm:text-sm border-gray-300 border-2 rounded-md"
                      placeholder="******"
                      {...register("password", { required: true })}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <div
                      className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                      onClick={showPassword}
                    >
                      {hidePassword ? (
                        <EyeOffIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      ) : (
                        <EyeIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      )}
                    </div>
                  </div>
                  {errors.password?.type === "required" && (
                    <span
                      className={`block text-xs text-red-600 mt-2 font-semibold`}
                    >
                      Please enter a password
                    </span>
                  )}
                </div>
              )}
            </>
          )}

          {addDetailsInput && (
            <>
              <div className="text-left mb-4">
                <h1 className="text-3xl text-center">
                  Complete your details 😉
                </h1>
              </div>
              <div className="mb-3 mt-2">
                <label htmlFor="email" className="block text-sm font-medium">
                  Your gender
                </label>
                <div className="mt-1 flex space-x-4">
                  <div className="flex items-center">
                    <input
                      id="male"
                      name="gender"
                      type="radio"
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                      onChange={() => setIsMale(true)}
                    />
                    <label
                      htmlFor="male"
                      className="ml-3 block text-sm font-medium text-gray-700"
                    >
                      Male
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      id="female"
                      name="gender"
                      type="radio"
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                      onChange={() => setIsMale(false)}
                    />
                    <label
                      htmlFor="female"
                      className="ml-3 block text-sm font-medium text-gray-700"
                    >
                      Female
                    </label>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="block text-sm font-medium">
                  Your date of birth
                </label>
                <div className="mt-2 relative rounded-md shadow-sm">
                  <input
                    type="date"
                    id="dob"
                    autoComplete="dob"
                    className="focus:ring-gray-300 focus:border-gray-300 block w-full pl-7 pr-12 py-4 sm:text-sm border-gray-300 border-2 rounded-md"
                    placeholder="DD/MM/YYYY"
                    onChange={(e) => {
                      setDob(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="block text-sm font-medium">
                  Who referred you? (Optional)
                </label>
                <div className="mt-2 relative rounded-md shadow-sm">
                  <input
                    type="text"
                    id="referralCode"
                    className="focus:ring-gray-300 focus:border-gray-300 block w-full pl-7 pr-12 py-4 sm:text-sm border-gray-300 border-2  rounded-md"
                    placeholder="Enter referral code"
                    defaultValue={refCode || ""}
                    onChange={(e) => setReferredBy(e.target.value)}
                  />
                </div>
                <span className={`block text-xs mt-2 font-semibold`}>
                  Enter the referral code of the one who referred you. If none,
                  enter TENDO.
                </span>
              </div>{" "}
            </>
          )}
          <div className="flex flex-col py-4">
            <div className="my-2 w-full">
              <button
                type="submit"
                className={`w-full flex justify-center py-4 px-4 border border-transparent text-base font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 text-white ${
                  isLoadingRegisterAccount || isLoadingUpdateUserDetails
                    ? "cursor-not-allowed bg-gray-200"
                    : "bg-blue-500"
                }`}
                disabled={
                  isLoadingRegisterAccount || isLoadingUpdateUserDetails
                    ? true
                    : false
                }
              >
                {(isLoadingRegisterAccount || isLoadingUpdateUserDetails) && (
                  <AiOutlineLoading3Quarters className="h-6 w-6 animate-spin text-blue-400 mr-2" />
                )}
                {addDetailsInput ? "Continue" : "Create Account"}
              </button>
            </div>
            <div>
              <button
                onClick={signInWithGoogle}
                className="w-full bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow flex items-center justify-center transition duration-300 ease-in-out"
                type="button"
              >
                {/* {loading && (
              <AiOutlineLoading3Quarters className="h-6 w-6 animate-spin text-blue-400 mr-2" />
            )}{" "} */}
                <svg
                  className="w-5 h-5 mr-2"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                    fill="#4285F4"
                  />
                  <path
                    d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                    fill="#34A853"
                  />
                  <path
                    d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                    fill="#FBBC05"
                  />
                  <path
                    d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                    fill="#EA4335"
                  />
                  <path d="M1 1h22v22H1z" fill="none" />
                </svg>
                Sign up with Google
              </button>
            </div>
            <p className="text-sm text-center font-semibold text-gray-400 mt-3">
              Already a reseller?{" "}
              <span
                className="ml-1 text-blue-500 cursor-pointer"
                onClick={() => {
                  navigate("/auth");
                }}
              >
                Sign in here
              </span>
            </p>
          </div>
          <p className="text-center text-sm text-gray-400 mt-2">
            By clicking on create an account <br />
            you have agreed to our{" "}
            <a
              href="https://rebrand.ly/tendo-policy"
              target="_blank"
              rel="noopener noreferrer"
              className="ml-1 text-blue-500"
            >
              Terms &amp; Conditions
            </a>
          </p>
        </form>
      )}
    </div>
  );
};

export default RegisterForm;
