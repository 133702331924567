import { useQuery } from "@apollo/client";

import {
  GetProductCategoriesDocument,
  GetProductCategoriesLengthDocument,
  GetProductCategoriesLengthQuery,
  GetProductCategoriesLengthQueryVariables,
  GetProductCategoriesQuery,
  GetProductCategoriesQueryVariables,
} from "../graphql/queries/__generated__/products.query";

const useGetProductCategories = (
  variables: GetProductCategoriesQueryVariables
) => {
  const { data, loading, error, fetchMore, networkStatus } = useQuery<
    GetProductCategoriesQuery,
    GetProductCategoriesQueryVariables
  >(GetProductCategoriesDocument, {
    fetchPolicy: "cache-first",
    variables,
  });

  const {
    data: _data,
    loading: _loading,
    error: _error,
  } = useQuery<
    GetProductCategoriesLengthQuery,
    GetProductCategoriesLengthQueryVariables
  >(GetProductCategoriesLengthDocument, {
    fetchPolicy: "cache-and-network",
  });
  return {
    categories: data?.getProductCategories ?? [],
    count: !_loading && !_error ? _data?.getProductCategoriesLength : 0,
    loading,
    error,
    fetchMore,
    networkStatus,
  };
};

export default useGetProductCategories;
